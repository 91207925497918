/**
 * @file スライダー
 * http://kenwheeler.github.io/slick/
*/

import '../lib/_owl.carousel.min';

$('.js-slider').owlCarousel({
  margin: 40,
  loop: true,
  autoWidth: true,
  items: 4,
  dots: false,
  nav: false,
  center: true,
  responsive : {
    0 : {
      margin: 20,
    },
    768 : {
      omargin: 40,
    }
  }
});
