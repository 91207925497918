const $header = $('#header');
const pathname = window.location.pathname.replace(/\//g, '');

const $menulist = $header.find('.menu-list');

$menulist.each(function (key, item) {
  const $items = $(item).children('li');
  $items.each(function (k, i) {
    const $link = $(i).children('a');
    const text = $link.find('.text-en').text().toLowerCase().replace(/\s/g, '');
    
    if (pathname === text) {
      $link.addClass('is-active');
    } else if (pathname === '' && k === 0) {
      if ($(window).width() < 897) {
        $link.addClass('is-active');
      }
    }
  });
});