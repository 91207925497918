import '../lib/_jquery.waypoints.min';

$(function () {
  // If it is not Homepage
  if (window.location.pathname != '/') {
    var waypoints = $('.js-animate, .js-animate-group > *').waypoint(function() {
      $(this.element).addClass('is-animated');
    },
    {
      offset: '80%',
      triggerOnce: true
    });
  }
});