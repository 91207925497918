var setViewport = function(){
  // $('<script async src="/assets/js/mobile-detect.js"></script>').insertAfter('head title');
  var vp_size = 1200;
  var md = new MobileDetect(window.navigator.userAgent);
  $('meta[name="viewport"]').remove();
  if(md.tablet()){    
    $('<meta name="viewport" content="width='+ vp_size + ',user-scalable=yes">').insertAfter('head title');
  }else{
    $('<meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">').insertAfter('head title');
  }
}

$(document).ready(function () {
  setViewport();
});