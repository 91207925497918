const Component = {
  NAME: 'Drawer',
  VERSION: '1.0.0',
};

const ClassNames = {
  DRAWER: 'js-drawer',
  ACTIVE: 'is-active',
};

const Events = {
  CLICK: 'click',
  KEYUP: 'keyup'
};

const Selector = {
  ROOT: 'BODY',
  DATA_TOGGLE: '[data-toggle="drawer"]',
  DATA_CLOSE: '[data-toggle="close"]',
  BACKDROPCLASS: 'c-overlay ',
};

const NOTIFICATIONS = {
  ERROR: {
    TARGET: 'Could you please to check [data-target] again ? Have no drawer target\'s',
  }
};

class Drawer {
  constructor (element) {
    this._element = $(element);
    this._root = $(Selector.ROOT);
    this._close = $(Selector.DATA_CLOSE);
    this.toggle = false;

    this._target = $(this._element.data('target'));

    try {
      if (this._target.length === 0) {
        throw `${ Component.NAME }: ${ NOTIFICATIONS.ERROR.TARGET }`;
      }
    } catch (error) {
      console.error(error);
    }

    this._element.bind(Events.CLICK, e => this.handleClick(e));
    this._close.bind(Events.CLICK, e => this.handleClick(e));
    $(document).bind(Events.KEYUP, e => this.handleKeyUp(e));
  }

  handleClick (e) {
    e.preventDefault();

    if (!this.toggle) {
      this.enabledDrawer(true);
    } else {
      this.enabledDrawer(false);
    }
  }

  handleKeyUp (e) {
    if (e.keyCode === 27) {
      this.enabledDrawer(false);
    }
  }

  enabledDrawer (bool) {
    this.toggleDrawer(bool);
    this.toggleBackDrop(bool);
    this.lockedScreen(bool);
  }

  toggleDrawer (bool) {
    if (bool) {
      this._target.addClass(ClassNames.ACTIVE);
    } else {
      this._target.removeClass(ClassNames.ACTIVE);
    }

    this.toggle = bool;
  }

  toggleBackDrop (bool) {
    const backdrop = `<div class="${ Selector.BACKDROPCLASS }"></div>`;

    if (bool) {
      this._element.parent().append(backdrop);
    } else {
      const $backdrop = this._root.find(`.${ Selector.BACKDROPCLASS }`);
      $backdrop.remove();
    }
  }

  lockedScreen (bool) {
    const lockscreenProps = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
    };

    if (bool) {
      this._root.css(lockscreenProps);
    } else {
      this._root.removeAttr('style');
    }
  }

}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
const drawer = new Drawer(Selector.DATA_TOGGLE);

export default Drawer;
