$(() => {
  if ($('.js-list-instagram').length > 0) {
    const maxLength = 80;
  
    $('.js-list-instagram').find('.text').each((index, el) => {
      let text = $(el).text();
      let newText = '';

      if (text.length > maxLength) {
        newText = text.substr(0, maxLength) + '...';
        $(el).text(newText);
      }
    });
  }
});
